<template>
  <div class="helpDocument">
    <div class="type-area doc__detail">
      <crumbs :crumbs="crumbs"/>
    </div>
    <div class="type-area">

      <div class="helpDocumentRouter">
        <div class="helpDocumentRouterLink">
          <div class="helpDocument-title">帮助文档</div>
          <div class="left-title-scroll">
            <el-menu :default-openeds="openeds"
                     class="el-menu-vertical-demo"
                     @open="handleOpen"
                     @close="handleClose"
                     :collapse="isCollapse"
                     :unique-opened="false">
              <el-submenu index="1">
                <template slot="title">
                  <span slot="title">产品</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                      index="1-indexs"
                      v-for="(item, indexs) in product"
                      :key="indexs"
                      @click="toSubView(item)">
                    <div @click="toViews(item.id)" :class="activeTag==item.id?'activeTag':''">
                      {{item.title}}
                    </div>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="2">
                <template slot="title">
                  <span slot="title">开发</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="1-indexs" v-for="(item, indexs) in theDevelopment"
                                :key="item.id"
                                @click="toSubView(item)">
                    <div @click="toViews(item.id)" :class="activeTag==item.id?'activeTag':''">
                      {{item.title}}
                    </div>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="3">
                <template slot="title">
                  <span slot="title">设计</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="1-indexs" v-for="(item, indexs) in theDesign"
                                :key="item.id"
                                @click="toSubView(item)">
                    <div @click="toViews(item.id)" :class="activeTag==item.id?'activeTag':''">
                      {{item.title}}
                    </div>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </div>

        </div>
        <div class="helpDocumentRouterView">
          <!--<router-view class="views"/>-->
          <ios/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PubSub from "pubsub-js";
  import axios from "axios";
  import { mobileList } from "@/api/request";
  import Crumbs from "@/components/common/crumbs/Crumbs";
  import Ios from "./product/Ios";

  export default {
    name: "HelpDocument",
    components: { Ios, Crumbs },
    data() {
      return {
        openeds: ["1"],
        crumbs: [
          { name: "ToolNavigation", title: "服务与支持", disable: true },
          { name: "HelpDocument", title: "帮助文档" }
        ],
        isCollapse: false,
        product: [],//产品
        theDevelopment: [],//开发
        theDesign: [],//设计
        activeTag: ""//选中列表项
      };
    },
    created() {
      axios.all([mobileList({ typeCode: "canpin", pageSize: "", pageNo: 1 }), mobileList({
        typeCode: "kaifa",
        pageSize: "",
        pageNo: 1
      }), mobileList({ typeCode: "sheji", pageSize: "", pageNo: 1 })])
        .then(axios.spread((canpin, kaifa, sheji) => {
          // 请求都执行完成
          if (canpin.code == 0 || canpin.code == 200) {
            this.product = canpin.result.records;
            PubSub.publish("toSubView", this.product[0]);
            let id = "";
            if (this.$route.query.id) {
              id = this.$route.query.id;
            } else {
              id = this.product[0].id;
            }
            // this.$router.push({ path: "/service/doc/detail", query: { id } });
            this.activeTag = id;
          }
          if (kaifa.code == 0 || kaifa.code == 200) {
            this.theDevelopment = kaifa.result.records;
            let id = this.$route.query.id;
            let result = this.theDevelopment.some(item => item.id == id);//  查找当前文章ID对应左侧的模块
            if (result) {
              this.openeds = ["2"];
            }
          }
          if (sheji.code == 0 || sheji.code == 200) {
            this.theDesign = sheji.result.records;
            let id = this.$route.query.id;
            let result = this.theDesign.some(item => item.id == id);
            if (result) {
              this.openeds = ["3"];
            }
          }

        }));

    },
    methods: {
      toViews(id) {
        this.activeTag = id;
        this.$router.push({ name: "Ios", query: { id } });
      },
      //获取二级子菜单  canpin、kaifa、sheji
      getSubNavBar(type) {
        mobileList(type).then(res => {
          if (res.code == 0 || res.code == 200) {
            if (type.typeCode == "canpin") {
              this.product = res.result.records;
            } else if (type.typeCode == "kaifa") {
              this.theDevelopment = res.result.records;
            } else {//sheji
              this.theDesign = res.result.records;
            }
          }

        });
      },
      handleOpen(key, keyPath) {
      },
      handleClose(key, keyPath) {
      },
      //去子视图页面
      toSubView(item) {
        PubSub.publish("toSubView", item);
      }
    },
    updated() {
      // 同步更新左侧激活状态
      PubSub.subscribe("updataId", (msg, data) => {
        this.activeTag = data;
      });
    }
  };
</script>

<style lang="less" scoped>
  .left-title-scroll{
    height: calc(100vh - 573px);
    /*height: 400px;*/
    overflow-y: auto;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar{
    /*display: none;*/
    background: #fff;
  }

  .el-submenu:last-child {
    padding-bottom: 20px;
  }

  /deep/ .el-submenu__title {
    color: #2F3133;
  }

  /deep/ .el-menu-item {
    color: #5C6066;
  }

  /deep/ .el-menu-vertical-demo li:hover {
    color: #0F80DC;
  }

  /deep/ .el-menu-item.is-active {
    color: #303133;
  }

  .activeTag {
    color: #0F80DC;
  }

  /deep/ .el-menu-item-group__title {
    display: none;
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 0px;
  }

  .el-menu {
    border-right: none;
  }

  a {
    color: #5C6066;
  }

  .helpDocument {
    background: rgb(240, 242, 245);
    padding-bottom: 100px;
    .doc__detail {
      // margin-left: 50%;
      margin: 0 auto;
      // position: fixed;
      // top: 61px;
      // left: -600px;
    }

    .helpDocumentRouter {
      // margin-top: 60px;
      display: flex;
      .helpDocumentRouterLink {
        width: 204px;
        position: fixed;

        .helpDocument-title {
          width: 180px;
          height: 52px;
          font-size: 16px;
          padding-left: 20px;
          font-family: MicrosoftYaHei;
          text-align: left;
          color: #2f3133;
          line-height: 52px;
          background: #fff;
          box-shadow: 0px 1px 0px 0px #ebedf0 inset;
          border-bottom: solid 1px #e6e6e6;
        }
      }
      .helpDocumentRouterView {
        width: 100%;
        margin-left: 228px;
        .views {
          background: #fff;
        }
      }
    }
  }

</style>
